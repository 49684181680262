/** @jsx jsx */
import { jsx } from 'theme-ui'
import SEO from '../components/Seo'
import Page from '../components/Page'

const FourOhFourPage = () => {

  return (
    <Page>
      <SEO />
      ¯\_(ツ)_/¯
    </Page>
  )
}

export default FourOhFourPage
